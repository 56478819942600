import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/",
    component: Layout
  },
  {
    path: "/admin/wwlogin",
    component: () => import("@/views/login/index")
  },
  {
    path: "/admin/fslogin",
    component: () => import("@/views/login/fslogin")
  },
  {
    path: '/admin/qywxlogin',
    component: () => import('@/views/login/qywxindex')
  },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
