import request from "@/utils/request";
import qs from "qs";

export function login(data) {
  // console.log(data)
  return request({
    url: "/admin/index/login",
    method: "post",
    data: qs.stringify(data)
  });
}
export function fsloginInfo(params) {
  // console.log(data)
  return request({
    url: "/admin/index/login",
    method: "get",
    params
  });
}

export function qyweixinLoginInfo(params){
  return request({
    url: "/admin/index/qyweixinPcLoginUrl",
    method: "get",
    params
  })
}

export function getInfo() {
  return request({
    url: "/admin/member/getInfo",
    method: "post"
    // params: qs.stringify({ token })
  });
}

export function logout() {
  return request({
    url: "/admin/admin/logout",
    method: "post"
  });
}

export function wwlogin(data) {
  return request({
    url: "/admin/index/wwlogin",
    method: "post",
    data: qs.stringify(data)
  });
}

export function fslogin(data) {
  return request({
    url: "/admin/index/fslogin",
    method: "post",
    data: qs.stringify(data)
  });
}

export function fsloginMobile(data) {
  return request({
    url: "/admin/index/fsloginMobile",
    method: "post",
    data: qs.stringify(data)
  });
}

export function qywxlogin(params)
{
  return request({
    url: '/admin/index/qywxlogin',
    method: 'get',
    params
  })
}
