var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",style:('height:' + _vm.windowHeight + 'px'),attrs:{"id":"vueDiv"}},[_c('div',{staticClass:"topMain"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logoImage}}),_c('div',{staticClass:"userView"},[_c('el-dropdown',{staticStyle:{"height":"100%"},attrs:{"trigger":"click"}},[_c('div',{staticStyle:{"height":"100%","padding-top":"5px"}},[_c('img',{attrs:{"src":_vm.avatar != '' ? _vm.avatar : _vm.default_avatar_circle}}),_c('i',{staticClass:"el-icon-caret-bottom triangleImg"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.logout()}}},[_vm._v("退出")])],1)],1)],1),_c('div',{staticClass:"userName"},[_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"bottomMain"},[_c('div',{staticClass:"leftMain"},[_vm._l((_vm.leftMenuList),function(item,index){return [(
            item.position == 1 &&
            (!item['has_submenu'] ||
              (item['submenu'] && item['submenu'].length > 0))
          )?_c('div',{key:index,class:'main-left-menu' +
            (_vm.menuType == item.id ? ' main-left-active' : ''),on:{"click":function($event){return _vm.selectMenu(index)}}},[_c('div',[_c('svg-icon',{class:'main-left-menu-icon' +
                (_vm.menuType == item.id ? ' main-left-active' : ''),attrs:{"icon-class":item.new_icon}})],1),_c('div',{staticClass:"main-left-menu-title"},[_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e()]}),_c('div',{staticStyle:{"position":"fixed","bottom":"8px"}},[_vm._l((_vm.leftMenuList),function(item,index){return [(
              item.position == 2 &&
              (!item['has_submenu'] ||
                (item['submenu'] && item['submenu'].length > 0))
            )?_c('div',{key:index,class:'main-left-menu' +
              (_vm.menuType == item.id ? ' main-left-active' : ''),on:{"click":function($event){return _vm.selectMenu(index)}}},[_c('div',[_c('svg-icon',{class:'main-left-menu-icon' +
                  (_vm.menuType == item.id ? ' main-left-active' : ''),attrs:{"icon-class":item.new_icon}})],1),_c('div',{staticClass:"main-left-menu-title"},[_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e()]})],2)],2),_c('div',{staticClass:"rightMain"},[(_vm.topMmenuList.length > 0)?_c('div',{staticClass:"topMenu"},[_vm._l((_vm.topMmenuList),function(item,index){return [(
              !item['has_submenu'] ||
              (item['submenu'] && item['submenu'].length > 0)
            )?_c('div',{key:index,class:_vm.topMenuSelectIndex == index
                ? 'selectMenuItemDiv'
                : 'menuItemDiv',on:{"click":function($event){return _vm.selectTopMenu(index)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"contentDiv",style:(_vm.topMmenuList.length > 0 ? '' : 'margin-top: 10px')},[(_vm.subMenuList.length > 0)?_c('div',{staticClass:"contentMenu",style:({ height: _vm.windowHeight - 78 + 'px' }),attrs:{"id":_vm.sliderHidden ? 'hiddenElem' : 'noHiddenElem'}},_vm._l((_vm.subMenuList),function(item,index){return _c('div',{key:item.id,class:_vm.leftContentMenuType == item.id
                ? 'selectContentMenuItem contentMenuItem'
                : 'contentMenuItem',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.selectContentMenu(index)}}},[(item.new_icon)?_c('img',{attrs:{"src":_vm.leftContentMenuType == item.id
                  ? '/static/admin/img/' +
                    item.new_icon +
                    '_1.png?t=2022021101'
                  : '/static/admin/img/' + item.new_icon + '.png?t=2022021101'}}):_vm._e(),_vm._v(" "+_vm._s(item.title)+" ")])}),0):_vm._e(),(_vm.subMenuList.length > 0)?_c('div',{staticClass:"slide-bar-left",on:{"click":_vm.hiddenSlideBar}},[_c('i',{class:'el-icon-d-arrow-' + (_vm.sliderHidden ? 'right' : 'left')}),_c('i',{class:'el-icon-d-arrow-' + (_vm.sliderHidden ? 'right' : 'left')}),_c('i',{class:'el-icon-d-arrow-' + (_vm.sliderHidden ? 'right' : 'left')})]):_vm._e(),_c('div',{staticClass:"content",class:_vm.subMenuList.length > 0 ? _vm.class_name : '',style:('height:' +
            (_vm.newHeight - 57 - (_vm.topMmenuList.length > 0 ? 68 : 20)) +
            'px')},[_c('router-view',{key:_vm.key})],1)]),_c('div',{staticClass:"watermark-box"},_vm._l((100),function(v){return _c('span',{key:v},[_vm._v("美哒 "+_vm._s(_vm.name)+_vm._s(_vm.mobile_suffix))])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }