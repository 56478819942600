import request from '@/utils/request'
import qs from 'qs'

export function getSubordinate(params)
{
  return request({
    url: '/admin/dynamic.common/getSubordinate',
    method: 'post',
    params
  })
}

export function check_detect(params)
{
  return request({
    url: '/admin/dynamic.common/check_detect',
    method: 'get',
    params
  })
}
