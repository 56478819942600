import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/styles/index.scss' // global css

import '@/icons' // icon
import '@/permission' // permission control

import store from '@/store'

import '@/rem' // rem

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

Vue.use(ElementUI)
VXETable.setup({
  size: 'small'
})
Vue.use(VXETable)

Vue.config.productionTip = false

// 权限判断
Vue.directive('auth', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: (el, binding, vnode) => {
    const value = binding.value;
    const rules = store.state.user.rules;
    const hasPermission = rules.includes(value);
    if (!hasPermission) {
      el.parentNode?.removeChild(el);
    }
  }
});


new Vue({
  router,
  store,
  data() {
    return {
      totalScore: 0,
      needRefreshTargetData: 0, // 是否需要刷新目标相关列表，关系图等，通过监听方式
      needRefreshWorktable: 0, // 是否需要刷新工作台
      isUseZoom: false, // 是否使用了zoom
	    needRefreshMessage:0,
    }
  },
  render: h => h(App),
}).$mount('#app')
